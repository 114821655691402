var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.fetchingFile
        ? _c(
            "div",
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("v-skeleton-loader", {
                            attrs: { type: "article@2" },
                          }),
                          _c("v-skeleton-loader", {
                            staticClass: "ml-4",
                            attrs: { type: "paragraph" },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("v-skeleton-loader", {
                            attrs: { type: "article@2" },
                          }),
                          _c("v-skeleton-loader", {
                            staticClass: "ml-4",
                            attrs: { type: "paragraph" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c("editor", {
            ref: "toastuiEditor",
            staticClass: "markdown-file-editor",
            attrs: {
              options: _vm.defaultOptions,
              height: _vm.editorHeight,
              initialEditType: _vm.editorMode,
              previewStyle: "vertical",
            },
            on: { change: _vm.setMarkdown, load: _vm.loaded },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }